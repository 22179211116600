import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, keywords, lang, meta, type, image, location, isProduct, isBlogPage, isBlogPost, blogpage, productName, sku, }) {
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                    author
                    image
                    social {
                        twitter
                        instagram
                        facebook
                    }
                }
            }
        }
    `
    )

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const date = new Date()
    let monthName = month[date.getMonth()];
    let year = date.getFullYear()
    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{ lang, }}
            title={title}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: type,
                },
                {
                    property: `og:url`,
                    content: `${site.siteMetadata.siteUrl}${location}`,
                },
                {
                    property: `og:image`,
                    content: `${site.siteMetadata.siteUrl}/${site.siteMetadata.image}`,
                },
                {
                    property: 'og:image:width',
                    content: `820`
                },
                {
                    property: 'og:image:height',
                    content: `312`
                },
                {
                    property: 'og:image:type',
                    content: `image/png`
                },
                {
                    property: `og:locale`,
                    content: `en_US`,
                },
                {
                    property: `og:site_name`,
                    content: `Pyxel Development`,
                },
                {
                    name: `twitter:creator`,
                    content: `@${site.siteMetadata.social.twitter}`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat(image ? [
                    {
                        property: `og:image`,
                        content: `${site.siteMetadata.siteUrl}/${site.siteMetadata.image}`,
                    },
                    {
                        property: `og:image:alt`,
                        content: title,
                    },
                    {
                        property: 'og:image:width',
                        content: `820`
                    },
                    {
                        property: 'og:image:height',
                        content: `312`
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    }
                ] : [
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                ])
                .concat(
                    keywords.length > 0
                        ? {
                            name: `keywords`,
                            content: keywords.join(`,`),
                        }
                        : []
                )
                .concat(meta)}
        >

            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@graph": [
                {    
                    "@type": "ProfessionalService",
                    "name": "Pyxel Development",
                    "image": "https://www.pyxeldev.com/pyxel-logo.png",
                    "logo": {
                        "@type" : "ImageObject",
                        "url" : "https://www.pyxeldev.com/pyxel-logo.png"
                    },
                    "description": "${metaDescription}",
                    "@id": "https://www.pyxeldev.com/",
                    "url": "https://www.pyxeldev.com/",
                    "telephone": "916-821-9044",
                    "priceRange": "$$$",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "500 Capitol Mall STE 2350",
                        "addressLocality": "Sacramento",
                        "addressRegion": "CA",
                        "postalCode": "95814",
                        "addressCountry": "US"
                    },
                    "location": {
                        "@type": "Place",
                        "geo": {
                            "@type": "GeoCircle",
                            "geoMidpoint": {
                            "@type": "GeoCoordinates",
                            "latitude": 38.5780883,
                            "longitude": -121.5014984
                            },
                        "geoRadius": "50"
                        }
                    },
                    "paymentAccepted": "Cash, Credit Card, Apple Pay, Venmo, CashApp",
                    "areaServed" : {
                        "@type": "GeoCircle",
                        "geoMidpoint": {
                            "@type": "GeoCoordinates",
                            "latitude": 38.5780883,
                            "longitude": -121.5014984
                        },
                        "geoRadius": "5000"
                    },
                    "openingHoursSpecification": {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday"
                    ],
                    "opens": "09:00",
                    "closes": "22:00"
                    },
                    "sameAs": [
                        "https://www.facebook.com/pyxeldevelopment/",
                        "https://www.instagram.com/pyxeldevelopment/",
                        "https://www.pyxeldev.com/"
                    ] 
                },
                {
                    "@type": "ImageObject",
                    "image": "https://www.pyxeldev.com/pyxel-logo.png",
                    "url": "https://www.pyxeldev.com/"
                },
                {
                    "@type": "ImageObject",
                    "image": "https://www.pyxeldev.com/favicon.svg",
                    "url": "https://www.pyxeldev.com/"
                },
                {
                    "@type": "WebPage",
                    "name": "${title}",
                    "@id": "${site.siteMetadata.siteUrl}${location}",
                    "url": "${site.siteMetadata.siteUrl}${location}",
                    "inLanguage": "en-US",
                    "description": "${metaDescription}",
                    "publisher": {
                        "@type": "ProfilePage",
                         "name": "Pyxel Development"
                    },
                    "about": {
                        "@type" : "Organization",
                        "name" : "Pyxel Development",
                        "legalName": "Pyxel Development LLC",
                        "foundingDate": "2021",
                        "founders": [
                            {
                                "@type": "Person",
                                "name": "Max"
                            }
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "customer support",
                            "telephone": "[+1916-821-9044]",
                            "email": "admin@pyxeldev.com"
                            },
                        "@id" : "https://www.pyxeldev.com/",
                        "url" : "https://www.pyxeldev.com/",
                        "sameAs" : [
                            "https://www.facebook.com/pyxeldevelopment/",
                            "https://www.instagram.com/pyxeldevelopment/"
                        ],
                        "image": {
                            "@type": "ImageObject",
                            "@id": "https://www.pyxeldev.com/pyxel-logo.png",
                            "caption": "Pyxel Development",
                            "width": "820",
                            "height": "312",
                            "url": "https://www.pyxeldev.com/pyxel-logo.png"
                        },
                        "logo": {
                            "@type": "ImageObject",
                            "@id": "https://www.pyxeldev.com/pyxel-logo.png",
                            "caption": "Pyxel Development",
                            "width": "820",
                            "height": "312",
                            "url": "https://www.pyxeldev.com/pyxel-logo.png",
                            "inLanguage": "en-US"
                        }
                    },
                    "isPartOf": {
                        "@type": "WebSite",
                        "name": "Pyxel Development",
                        "@id": "https://www.pyxeldev.com/",
                        "url": "https://www.pyxeldev.com/",
                        "description": "${metaDescription}",
                        "inLanguage": "en-US",
                        "publisher": {
                            "@type": "Organization",
                            "@id": "https://www.pyxeldev.com/",
                            "name": "Pyxel Development",
                            "sameAs": [
                                "https://www.facebook.com/pyxeldevelopment/",
                                "https://www.instagram.com/pyxeldevelopment/",
                                "https://www.pyxeldev.com/"
                            ] 
                        },
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.pyxeldev.com/{search_term_string}",
                             "query-input": "required name=search_term_string"
                        }
                    }
                }
            ]
            }`}
            </script>

            {(isBlogPage ? true : null) && <script type="application/ld+json">
                {`{
                            "@context": "https://schema.org/", 
                            "@type": "BreadcrumbList", 
                            "itemListElement": [{
                                "@type": "ListItem", 
                                "position": 1, 
                                "name": "Home",
                                "item": "https://www.pyxeldev.com/"  
                            },{
                                "@type": "ListItem", 
                                "position": 2, 
                                "name": "Blog",
                                "item": "https://www.pyxeldev.com/blog"  
                            }]
                        }`}
            </script>}

            {(isBlogPage ? true : null) && <script type="application/ld+json">
                {`{
                            "@context": "https://schema.org",
                            "@type": "BlogPosting",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://pyxeldev.com/blog"
                            },
                            "headline": "${title}",
                            "description": "${description}",
                            "image": "https://www.pyxeldev.com/pyxel-logo.png",
                            "logo": "https://www.pyxeldev.com/pyxel-logo.png",
                            "author": {
                                "@type": "Organization",
                                "name": "Pyxel Development",
                                "url": "https://pyxeldev.com/"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Pyxel Development",
                                "logo": {
                                 "@type": "ImageObject",
                                 "url": "https://www.pyxeldev.com/pyxel-logo.png"
                                }
                            },
                            "datePublished": "February 16th, 2022",
                            "dateModified": "${monthName} ${date.getDate()}, ${year}"
                        }`}
            </script>}

            {(isBlogPost ? true : null) && <script type="application/ld+json">
                {`{
                            "@context": "https://schema.org",
                            "@type": "BlogPosting",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://pyxeldev.com${blogpage.slug}"
                            },
                            "headline": "${blogpage.title}",
                            "description": "${blogpage.description}",
                            "image": "https://www.pyxeldev.com/pyxel-logo.png",
                            "logo": "https://www.pyxeldev.com/pyxel-logo.png",
                            "author": {
                                "@type": "Organization",
                                "name": "${blogpage.author}",
                                "url": "https://pyxeldev.com/"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Pyxel Development",
                                "logo": {
                                 "@type": "ImageObject",
                                 "url": "https://www.pyxeldev.com/pyxel-logo.png"
                                }
                            },
                            "datePublished": "${blogpage.date}",
                            "dateModified": "${blogpage.dateModified}"
                        }`}
            </script>}

            {(isBlogPost ? true : null) && <script type="application/ld+json">
                {`{
                            "@context": "https://schema.org/", 
                            "@type": "BreadcrumbList", 
                            "itemListElement": [{
                                "@type": "ListItem", 
                                "position": 1, 
                                "name": "Home",
                                "item": "https://www.pyxeldev.com/"  
                            },{
                                "@type": "ListItem", 
                                "position": 2, 
                                "name": "Blog",
                                "item": "https://www.pyxeldev.com/blog"  
                            },{
                                "@type": "ListItem", 
                                "position": 3, 
                                "name": "${blogpage.title}",
                                "item": "https://www.pyxeldev.com${blogpage.slug}"  
                            }]
                        }`}
            </script>}

            {(isProduct ? true : null) && <script type="application/ld+json">
                {`{
                "@context": "https://schema.org/", 
                "@type": "Product", 
                "name": "${productName}",
                "image": "https://www.pyxeldev.com/pyxel-logo.png",
                "logo": "https://www.pyxeldev.com/pyxel-logo.png",
                "description": "${description}",
                "brand": {
                    "@type": "Brand",
                    "name": "${productName}"
                },
                "mpn": "${sku}",
                "sku": "${sku}",
                "offers": {
                    "@type": "AggregateOffer",
                    "url": "https://www.pyxeldev.com${location}",
                    "priceCurrency": "USD",
                    "lowPrice": "100",
                    "highPrice": "25000",
                    "offerCount": "50"
                },
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "9.5",
                    "bestRating": "10",
                    "worstRating": "1",
                    "ratingCount": "5",
                    "reviewCount": "5"
                },
                "review": [{
                    "@type": "Review",
                    "reviewBody": "I had an amazing experience dealing with Pyxel Development. The guys really knew what they were doing and were able to answer all of my questions and help me get set-up with all of the services that my business needed (website design, website development, and SEO). They were able to get everything done quickly and i seen a big increase in my online conversion rate and an increase in my online presence!",
                    "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "10",
                    "bestRating": "10",
                    "worstRating": "1"
                    },
                    "author": {"@type": "Person", "name": "Tamara"}
                },{
                    "@type": "Review",
                    "reviewBody": "I was looking for a web design and SEO agency to take over and create a responsive website design and to develop my website and implement SEO marketing tactics in a reasonable time frame. The guys at pyxel development were easy to talk to, they were super nice, and were very informative and knowledgeable about all the services that I was looking for. They were able to provide me with an amazing website that looks better than all of my competitors. Will definitely recommend this local business and will do business again. Thanks!",
                    "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "9.5",
                    "bestRating": "10",
                    "worstRating": "1"
                    },
                    "author": {"@type": "Person", "name": "Yulia"}
                },{
                    "@type": "Review",
                    "reviewBody": "I am a small business owner in the Sacramento area and desperately needed a website to show my work and reach new clients. Pyxel Development was able to answer all of my questions and were able to offer me an Amazing price for a full website design, seo, and all of the other website marketing tactics.",
                    "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "9",
                    "bestRating": "10",
                    "worstRating": "1"
                    },
                    "author": {"@type": "Person", "name": "Mark"}
                },{
                    "@type": "Review",
                    "reviewBody": "I needed a website to grow and market my new construction company and I am glad that I chose Pyxel Development. The guys really knew what they were doing and were able to make me a Fully custom website design and implement seo and other marketing tactics all for a super affordable price. Did I mention that they had the product completed well before the estimated date and the final product was AMAZING!",
                    "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "9.5",
                    "bestRating": "10",
                    "worstRating": "1"
                    },
                    "author": {"@type": "Person", "name": "Sam"}
                },{
                    "@type": "Review",
                    "reviewBody": "Small business, BIG RESULTS. The owner has been integral to my new E-commerce business. They stayed within my budget and delivered.",
                    "reviewRating": {
                    "@type": "Rating",
                    "ratingValue": "10",
                    "bestRating": "10",
                    "worstRating": "1"
                    },
                    "author": {"@type": "Person", "name": "James"}
                }]
             }`}
            </script>}

            {(isProduct ? true : null) && <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org/", 
                    "@type": "Service", 
                    "serviceType": "${productName}",
                    "logo": "https://www.pyxeldev.com/pyxel-logo.png",
                    "image": "https://www.pyxeldev.com/pyxel-logo.png",
                    "url": "https://www.pyxeldev.com${location}",
                    "provider": {
                        "@type": "LocalBusiness",
                        "name": "Pyxel Development",
                        "image": "https://www.pyxeldev.com/pyxel-logo.png",
                        "telephone": "(916) 821-9044",
                            "priceRange": "$$$",
                            "openingHours": "Mo, Tu, We, Th, Fr 09:00-22:00",
                        "paymentAccepted" : "Cash, Credit Card, Apple Pay, Venmo, CashApp",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Sacramento, California",
                            "postalCode": "95814",
                            "streetAddress": "500 Capitol Mall, STE 2350"
                        },
                        "location": {
                            "@type": "Place",
                            "geo": {
                                "@type": "GeoCircle",
                                "geoMidpoint": {
                                "@type": "GeoCoordinates",
                                "latitude": 38.5780883,
                                "longitude": -121.5014984
                                },
                            "geoRadius": "50"
                            }
                        },
                        "areaServed" : {
                            "@type": "State",
                            "name": "California"
                        },
                        "brand": "Pyxel Development",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.pyxeldev.com/pyxel-logo.png",
                            "width": "820",
                            "height": "312"
                        },
                        "slogan": "At Pyxel Development, we focus on making your business successful. We don't stop working until you are happy!",
                        "description":"${metaDescription}"
                    }
                 }`}
            </script>}
        </Helmet>
    )
}

Seo.defaultProps = {
    description: ``,
    type: `WebSite`,
    lang: `en`,
    keywords: [],
    meta: [],
    location: ``,
    isProduct: false,
    isBlogPage: false,
    isBlogPost: false,
    productName: `Pyxel Development`,
    sku: `10000000`,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.object,
    location: PropTypes.string,
    isProduct: PropTypes.bool,
    isBlogPage: PropTypes.bool,
    isBlogPost: PropTypes.bool,
    productName: PropTypes.string,
    sku: PropTypes.string,
}

export default Seo
